(function($) {

    if(window.location.hash) {
      var hash = window.location.hash;

      $('html, body').animate({
        scrollTop: $(hash).offset().top - 140
      }, 1500, 'swing');
    }

    $(document).ready(function() {


        /**
         * Menu Toggle
         */
        var menuOpen = false;
        $('.js-menu-toggle').on('click', function(evt) {
            evt.preventDefault();

            $(this).toggleClass('isOpen');
            $('.js-nav-primary').toggleClass('isOpen');
            $('.js-site-head').toggleClass('isOpen');
        });

        $('.js-menu-close').on('click', function(evt) {
            evt.preventDefault();
            closeMenu();
        });


        function closeMenu() {
            $('.js-menu-toggle').removeClass('isOpen');
            $('.js-nav-primary').removeClass('isOpen');
            $('.js-site-head').removeClass('isOpen');
        }

        $(window).resize(function(){
            closeMenu();
        });

        $('.js-nav-primary a').on('click', function(){
          closeMenu();
        });


        /*
         * Alt Header on scroll
         */
        stickHeader();
        window.addEventListener('scroll', function(e){
            stickHeader();
        });

        function stickHeader() {
            var distanceY = window.pageYOffset || document.documentElement.scrollTop,
                shrinkOn = 100,
                header = $('.js-site-head');
            if (distanceY > shrinkOn) {
                header.addClass('isSticky');

            } else {
                if (header.hasClass('isSticky')) {
                    header.removeClass('isSticky');
                }
            }
        }

        $('.js-toggle').on('click', function(evt) {
            evt.preventDefault();
            $($(this).data('toggle-element')).toggleClass($(this).data('toggle-class'));
        });

        /**
         * Tabs
         */
        // $('.js-accordion-control').on('click', function(evt) {
        //     evt.preventDefault();

        //     var $tab = $(this).closest('.js-accordion-item');
        //     var $this = $(this);

        //     $tab.find('.accordion-body').toggleClass('isActive');
        //     $this.toggleClass('isActive');
        // });


        /**
         *
         * SmoothScroll
         *
         */
        $('a[href*="#"]:not([href="#"])').click(function() {
            if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
              var target = $(this.hash);
              target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
              if (target.length) {
                $('html, body').animate({
                  scrollTop: target.offset().top - 120
                }, 1000);
                return false;
              }
            }
          });





        /**
         *
         * Slick
         *
         */
        $('.js-slick').slick();

        // Video slides
        $('.js-slick-video').on('beforeChange', function(event, slick, currentSlide) {
            $('.bannerVideo')[0].pause();
        });

        $('.js-slick-video').on('afterChange', function(event, slick, currentSlide) {
            playSlideVideo();
        });

        playSlideVideo();

        function playSlideVideo() {
            if ($('.slick-active').find('.bannerVideo').length) {
                $('.bannerVideo')[0].play();
            }
        }


        /**
         *
         * AOS int
         *
         */
        // AOS.init({
        //     offset: 200,
        //     easing: 'ease-in-out-cubic'
        // });


        /**
         *
         * Products
         *
         */
        $('.woocommerce-Reviews-title').on('click', function(evt) {
            $('html, body').animate({
                scrollTop: $('#review_form_wrapper').offset().top - 150
            }, 1000);
        });


        /**
         *
         * Mailchimp
         *
         */
        $('form.js-formMailchimp').on('submit', function ( event ) {
            if ( event ) event.preventDefault();
            registerMailchimp($(this));
        });
        function registerMailchimp($form) {
            $.ajax({
                type: $form.attr('method'),
                url: $form.attr('action'),
                data: $form.serialize(),
                cache       : false,
                dataType    : 'json',
                contentType: "application/json; charset=utf-8",
                error       : function(err) { alert("Could not connect to the registration server. Please try again later."); },
                success     : function(data) {
                    if (data.result != "success") {
                    console.log(data.msg);
                        // Something went wrong, do something to notify the user. maybe alert(data.msg);
                        if(data.msg === '0 - An email address must contain a single @') {
                            $message = 'Invalid email address, please check and try again.';
                        } else if(data.msg === '0 - Please enter a value') {
                            $message = 'Please enter an email address';
                        } else {
                            $message = 'Looks like you\'ve already signed up.<br>Please check your emails for our latest secret news and offers.';
                        }
                        $form.find('.mc-error').remove();
                        $form.append('<span class="mc-error">'+$message+'</span>');
                    } else {
                        $form.html('<div class="cms-body text-center">Thank you for signing up, please check your email for confirmation.</div>');
                    }
                }
            });
        }

    });

})(jQuery); // Fully reference jQuery after this point.
